import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
// eslint-disable-next-line @backstage/no-forbidden-package-imports
import data from 'app/public/techradar.json';


export class OrigoOwnClient implements TechRadarApi {
  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    return data;
    };
  
}