import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path: {
    fill: '#16958A',
  },
  st0: {
    fill: '#1F9B90',
  },
  st1: {
    fill: '#FFFFFF',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 160"
    >
      <path className={classes.st0} d="M95.2,62.1c0-6.9-1.9-13.4-5.1-18.9l9-9L85.9,20.9l-9,9c-5.5-3.3-12-5.2-18.8-5.2C37.6,24.8,21,41.5,21,62.1
        c0,20.6,16.6,37.3,37.1,37.3c10.2,0,18.5,8.3,18.5,18.6c0,10.3-8.3,18.6-18.5,18.6c-10.2,0-18.5-8.3-18.5-18.6
        c0-4.4,1.5-8.4,4.1-11.6c-6.1-2-11.6-5.2-16.2-9.4c-4,6-6.4,13.2-6.4,20.9c0,20.6,16.6,37.3,37.1,37.3c20.5,0,37.1-16.7,37.1-37.3
        c0-11.1-4.9-21.1-12.6-28C90.3,83.2,95.2,73.2,95.2,62.1z M39.5,62.1c0-10.3,8.3-18.6,18.5-18.6c10.2,0,18.5,8.3,18.5,18.6
        c0,10.3-8.3,18.6-18.5,18.6C47.8,80.7,39.5,72.3,39.5,62.1z"/>
      <path className={classes.st1} d="M67.4,117.9c0,5.1-4.2,9.3-9.3,9.3c-5.1,0-9.3-4.2-9.3-9.3c0-5.1,4.2-9.3,9.3-9.3
        C63.2,108.6,67.4,112.8,67.4,117.9"/>
    </svg>
  );
};

export default LogoIcon;
