import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path: {
    fill: '#16958A',
  },

  st0: {
    fill: "#FFFFFF",
  },

	st1: {
    fill: "#1F9B90"
  }
});
const LogoFull = () => {
  const classNamees = useStyles();

  return (
    <svg
      className={classNamees.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 160"
    >
      <path className={classNamees.st0} d="M368.6,117.9c0-5.1-4.2-9.3-9.3-9.3c-5.1,0-9.3,4.2-9.3,9.3v9.3h4.6l0-9.3c0-2.6,2.1-4.7,4.6-4.7
        c2.6,0,4.6,2.1,4.6,4.7v9.3h4.6V117.9z"/>
      <path className={classNamees.st0} d="M345.1,120.3c0.2-0.7,0.3-1.5,0.3-2.3c0-5.1-4.2-9.3-9.3-9.3s-9.8,4.2-9.8,9.3s4.7,9.3,9.8,9.3
        c2.6,0,4.9-1,6.6-2.7l-3.3-3.3c-0.8,0.8-2,1.4-3.3,1.4c-1.7,0-3.2-0.9-4-2.3L345.1,120.3L345.1,120.3z M336.1,113.3
        c1.7,0,3.2,0.9,4,2.3h-8C332.9,114.2,334.4,113.3,336.1,113.3"/>
      <path className={classNamees.st0} d="M391.5,120.3c0.2-0.7,0.3-1.5,0.3-2.3c0-5.1-4.2-9.3-9.3-9.3c-5.1,0-9.3,4.2-9.3,9.3s4.2,9.3,9.3,9.3
        c2.6,0,4.9-1,6.6-2.7l-3.3-3.3c-0.8,0.8-2,1.4-3.3,1.4c-1.7,0-3.2-0.9-4-2.3L391.5,120.3L391.5,120.3z M382.5,113.3
        c1.7,0,3.2,0.9,4,2.3h-8C379.3,114.2,380.8,113.3,382.5,113.3"/>
      <rect x="433.5" y="108.6" className={classNamees.st0} width="4.6" height="18.6"/>
      <path className={classNamees.st0} d="M396.4,117.9l0,9.3h4.6l0-9.3c0-2.6,2.1-4.7,4.6-4.7l0-4.7C400.6,108.6,396.4,112.8,396.4,117.9"/>
      <path className={classNamees.st0} d="M461.4,117.9c0-5.1-4.2-9.3-9.3-9.3c-5.1,0-9.3,4.2-9.3,9.3c0,5.2,4.2,9.3,9.3,9.3c1.7,0,3.3-0.5,4.6-1.3v1.3
        h4.6L461.4,117.9C461.4,118,461.4,118,461.4,117.9 M452.1,122.6c-2.6,0-4.6-2.1-4.6-4.7c0-2.6,2.1-4.7,4.6-4.7
        c2.6,0,4.6,2.1,4.6,4.7C456.7,120.5,454.7,122.6,452.1,122.6"/>
      <path className={classNamees.st0} d="M428.9,117.9c0-1.7-0.5-3.3-1.3-4.7l2.2-2.3l-3.3-3.3l-2.2,2.3c-1.4-0.8-3-1.3-4.7-1.3c-5.1,0-9.3,4.2-9.3,9.3
        c0,5.1,4.2,9.3,9.3,9.3c2.6,0,4.6,2.1,4.6,4.7c0,2.6-2.1,4.7-4.6,4.7c-2.6,0-4.6-2.1-4.6-4.7h-4.6c0,5.1,4.2,9.3,9.3,9.3
        c5.1,0,9.3-4.2,9.3-9.3c0-2.8-1.2-5.3-3.1-7C427.7,123.2,428.9,120.7,428.9,117.9 M415,117.9c0-2.6,2.1-4.7,4.6-4.7
        c2.6,0,4.6,2.1,4.6,4.7c0,2.6-2.1,4.7-4.6,4.7C417.1,122.6,415,120.5,415,117.9"/>
      <path className={classNamees.st1} d="M368.6,24.8c-20.5,0-37.1,16.7-37.1,37.3s16.6,37.3,37.1,37.3c20.5,0,37.1-16.7,37.1-37.3
        S389.1,24.8,368.6,24.8 M368.6,80.7c-10.2,0-18.5-8.3-18.5-18.6c0-10.3,8.3-18.6,18.5-18.6c10.2,0,18.5,8.3,18.5,18.6
        C387.1,72.3,378.8,80.7,368.6,80.7"/>
      <path className={classNamees.st1} d="M131.3,34.1l-13.1-13.2l-9,9c-5.5-3.3-12-5.2-18.8-5.2c-20.5,0-37.1,16.7-37.1,37.3c0,20.6,16.6,37.3,37.1,37.3
        s37.1-16.7,37.1-37.3c0-6.9-1.9-13.4-5.1-18.9L131.3,34.1z M90.3,80.7c-10.2,0-18.5-8.3-18.5-18.6c0-10.3,8.3-18.6,18.5-18.6
        s18.5,8.3,18.5,18.6C108.9,72.3,100.6,80.7,90.3,80.7z"/>
      <rect x="201.6" y="24.8" className={classNamees.st1} width="18.6" height="74.6"/>
      <path className={classNamees.st1} d="M145.9,62.1l0,37.3h18.5l0-37.3c0-10.3,8.3-18.6,18.5-18.6V24.8C162.5,24.8,145.9,41.5,145.9,62.1"/>
      <path className={classNamees.st1} d="M312.9,62.1c0-6.9-1.9-13.4-5.1-18.9l9-9l-13.1-13.2l-9,9c-5.5-3.3-12-5.2-18.8-5.2
        c-20.5,0-37.1,16.7-37.1,37.3c0,20.6,16.6,37.3,37.1,37.3c10.2,0,18.5,8.3,18.5,18.6c0,10.3-8.3,18.6-18.5,18.6
        c-10.2,0-18.5-8.3-18.5-18.6c0-4.4,1.5-8.4,4.1-11.6c-6.1-2-11.6-5.2-16.2-9.4c-4,6-6.4,13.2-6.4,20.9c0,20.6,16.6,37.3,37.1,37.3
        c20.5,0,37.1-16.7,37.1-37.3c0-11.1-4.9-21.1-12.6-28C308.1,83.2,312.9,73.2,312.9,62.1z M257.3,62.1c0-10.3,8.3-18.6,18.5-18.6
        c10.2,0,18.5,8.3,18.5,18.6c0,10.3-8.3,18.6-18.5,18.6C265.6,80.7,257.3,72.3,257.3,62.1z"/>
      <path className={classNamees.st0} d="M285.1,117.9c0,5.1-4.2,9.3-9.3,9.3c-5.1,0-9.3-4.2-9.3-9.3c0-5.1,4.2-9.3,9.3-9.3
        C280.9,108.6,285.1,112.8,285.1,117.9"/>
    </svg>
  );
};

export default LogoFull;
